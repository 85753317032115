import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 45 min. `}<meta itemProp="prepTime" content="PT45M" /></h5>
    <h5>{`Cook time: 90 min. `}<meta itemProp="cookTime" content="PT90M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`6-8`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Olive oil, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Ground turkey, 2 lbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Onions, 2 chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 4 cloves chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Green or red pepper, 1 chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Celery, 1 cup chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Carrots, 4 chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Oregano, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Bay leaves, 2</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Chili powder, 3 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cumin, 2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Canned diced tomatoes, 28 oz.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Chicken broth, 2 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Red kidney beans, 2 15 oz. cans drained
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Jalapeno peppers, 1/4 cup sliced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Frozen corn, 1-2 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Cheese, shredded for serving (Cabot New York Extra Sharp Cheddar)
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sour cream, for serving</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Large pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Thaw ground turkey</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Grate cheese</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop onions</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop garlic</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop bell pepper</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop celery</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop carrots</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Over high heat, cook oil and ground turkey until lightly browned, about 5 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add onions, garlic, bell pepper, celery, and carrots.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add oregano, bay leaves, chili powder, and cumin. Stir and cook for another 5 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add tomatoes, chicken broth, salt, and pepper.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bring to a boil then reduce heat and simmer for 15 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add beans, jalapeno peppers, and corn.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Stir and cook for at least 10 more minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Serve with shredded cheese and sour cream.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      